<template>
  <div class="blockInfo">
    <span v-if="title" class="bockTitle">{{ title }}</span>
    <input
      :type="type ? type : 'text'"
      :name="title"
      :value="value"
      :maxlength="maxlength"
      class="bockInput"
      :placeholder="placeholder"
      :disabled="disabled"
      autocomplete="off"
      v-on:input="updateValue($event.target.value)"
      @keyup="$emit('input-change', $event.target.value)"
    />
    <CButton
      v-if="buttonDelete"
      size="sm"
      class="buttonDelete"
      @click="removeElement(_key)"
    >
      <CIcon name="cil-trash" />
    </CButton>
  </div>
</template>

<script>
export default {
  name: "BlockInfo",
  props: {
    title: String,
    _key: String,
    value: {
      type: [Number, String],
    },
    placeholder: String,
    disabled: Boolean,
    buttonDelete: Boolean,
    type: String,
    maxlength: Number,
  },
  computed: {},
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    removeElement(_key) {
      this.$emit("block-info-remove", _key);
    },
  },
};
</script>

<style scoped>
.blockInfo {
  padding-top: 0.5em;
  padding-bottom: 1em;
  margin-bottom: 0.5em;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);

  position: relative;
}
.bockTitle {
  width: 50%;
}
.bockInput {
  border: none;
  display: block;
  width: 100%;
  padding: 0.5em 1em;
  font-size: 14px;
  margin: 0;
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgb(124, 124, 124);
}
.bockInput:active,
.bockInput:hover,
.bockInput:focus {
  border: none;
  outline: none;
}

.buttonDelete {
  position: absolute;
  right: 0.5em;
  cursor: pointer;
}
</style>