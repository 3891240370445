<template>
  <div>
    <div class="sectionButtons-actions">
      <CButton
        color="success"
        size="lg"
        class="sectionButtons-actionsItem"
        @click="openCardNewShipTo()"
        >{{ $t('new') }} {{$t('shipto')}}</CButton
      >
    </div>
    <CCard accent-color="primary">
      <CCardHeader color="white">
        <b class="text-dark">{{ tableTitle }}</b>
      </CCardHeader>
      <CCardBody>
        <!-- section search -->
        <BlockColumn>
          <BlockItem :size="'1-2'">
            <BlockInfoSelect
              :title="$t('country') + ' :'"
              :selected="countryChossed"
              :values="countryCodes"
              @change-value="changeCountry"
            />
          </BlockItem>
        </BlockColumn>

        <CDataTable
          class="mb-0 table-outline"
          hover
          :items="tableItems"
          :fields="tableFields"
          head-color="light"
          no-sorting
          :items-per-page="10"
          pagination
          :table-filter="{label: $t('table_filter')}"
          :items-per-page-select="{label: $t('items_page')}"
        >
          <template #expiration_date="{ item }">
            <td>
              {{ moment(item.expiration_date).format("YYYY/MM/DD") }}
            </td>
          </template>

          <template #options="{ item }">
            <td class="text-right">
              <div class="buttons">
                <CButton
                  size="sm"
                  class=""
                  @click="openCardSendSMSShipTo(item)"
                >
                  <CIcon name="cil-speech" />
                </CButton>
                <CButton size="sm" class="" @click="openCardEditShipTo(item)">
                  <CIcon name="cil-pencil" />
                </CButton>
                <CButton size="sm" class="" @click="openCardDeleteShipTo(item)">
                  <CIcon name="cil-trash" />
                </CButton>
              </div>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
const moment = require("moment");
import BlockColumn from "./BlockColumn";
import BlockItem from "./BlockItem";
import BlockInfoSelect from "./BlockInfoSelect";
export default {
  name: "ListShipTo",
  components: {
    BlockColumn,
    BlockItem,
    BlockInfoSelect,
  },
  props: {
    tableItems: Array,
    countryCodes: Array,
    countryChossed: String,
  },
  computed: {
    _country() {
      return this.countryA;
    },
  },
  data() {
    return {
      layout: "list",
      tableTitle: "ShipTos",
      tableFields: [
        { key: "shipTo_number", label: "ShipTo Number" },
        { key: "shipTo_name", label: "ShipTo Name" },
        { key: "pod_code", label: "Pod Code" },
        { key: "expiration_date", label: "Expiration Date" },
        { key: "email", label: "Email" },
        { key: "phone_number", label: "Phone Number" },
        { key: "send_dn", label: "Send" },
        { key: "countryCode", label: "Country" },
        {
          key: "options",
          label: "",
          sorter: false,
          filter: false,
        },
      ],

      listStatus: [],
    };
  },
  async created() {
    const self = this;
    self.listStatus = [
      { _key: "0", value: "All status" },
      { _key: "1", value: "Approved" },
      { _key: "2", value: "Pending" },
    ];
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    changeCountry(country) {
      this.$emit("shipTo-countries", country);
    },
    openCardEditShipTo(shipTo) {
      this.$emit("shipTo-edit", {
        shipToSelected: shipTo,
      });
    },
    openCardNewShipTo(shipTo) {
      this.$emit("shipTo-new", {});
    },
    openCardDeleteShipTo(shipTo) {
      this.$emit("shipTo-delete", { shipToSelected: shipTo });
    },
    openCardSendSMSShipTo(shipTo) {
      this.$emit("shipTo-sendSMS", {
        shipToSelected: shipTo,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sectionButtons-actions {
  padding: 1em 0 2em 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sectionButtons-actionsItem {
  margin-left: 1em;
  font-size: 14px;
}

.buttons {
  display: flex;
}
</style>