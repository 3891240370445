<template>
  <div class="cardShipToSendSMS">
    <div class="cardShipToSendSMS-content">
      <div class="btnClose" @click="sendEvent()">
        <CIcon name="cil-x" height="25" />
      </div>

      <div class="cardShipToSendSMS-title">{{ "Send Pod Code" }}</div>
      <hr />

      <div v-if="notification">
        <CAlert :color="notificationType">
          {{ notification }}
        </CAlert>
      </div>

      <BlockInfo
      :title="$t('shipto') + ' ' + $t('number')"
        v-model="_shipTo.shipTo_number"
        :disabled="true"
      />
      <BlockInfo
      :title="$t('shipto') + ' ' + $t('name')"
        v-model="_shipTo.shipTo_name"
        :disabled="true"
      />
      <BlockInfo :title="$t('pod_code')" v-model="_shipTo.pod_code" :disabled="true" />
      <BlockInfo
        :title="$t('phone_number')"
        v-model="_shipTo.phone_number"
        :selected="_shipTo.phone_number"
        :disabled="true"
      />
      <BlockInfo
        :title="$t('email')"
        v-model="_shipTo.email"
        :selected="_shipTo.email"
        :disabled="true"
      />
      <BlockInfoSelect
        :title="$t('country')"
        v-model="_shipTo.countryCode"
        :selected="_shipTo.countryCode || countryCodes[0]"
        :values="countryCodes"
        :disabled="true"
      />

      <div class="cardShipToSendSMS-sectionButtons">
        <CButton
          color="white"
          size="lg"
          class="cardShipToSendSMS-button"
          @click="sendEvent()"
          >{{$t('cancel')}}</CButton
        >
        <CButton
          :color="'success'"
          size="lg"
          class="cardShipToSendSMS-button"
          @click="actionEventSMS()"
          :disabled="disabled_SMS_btn"
          >{{ send_SMS_btn }}</CButton
        >
        <CButton
          :color="'success'"
          size="lg"
          class="cardShipToSendSMS-button"
          @click="actionEventEmail()"
          :disabled="disabled_Email_btn"
          >{{ send_Email_btn }}</CButton
        >
      </div>
    </div>
    <div class="cardShipToShadow" @click="sendEvent()"></div>
  </div>
</template>

<script>
import BlockInfo from "./BlockInfo";
import BlockColumn from "./BlockColumn";
import BlockItem from "./BlockItem";
import BlockInfoSelect from "./BlockInfoSelect";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
const moment = require("moment");

export default {
  name: "cardShipToSendSMS",
  components: {
    BlockInfo,
    BlockColumn,
    BlockItem,
    BlockInfoSelect,
    DatePicker,
  },
  props: {
    shipTo: Object,
    countryCodes: Array,
    notification: String,
    notificationType: String,
    disabled_SMS_btn: Boolean,
    disabled_Email_btn: Boolean,
    send_SMS_btn: String,
    send_Email_btn: String
  },

  computed: {
    _shipTo() {
      return this.shipTo;
    },
  },
  data() {
    return {};
  },
  created() {
    const self = this;
  },
  methods: {
    sendEvent() {
      (this.classHidden = "hidden"), this.$emit("close-card", false);
    },

    actionEventSMS() {
      const self = this;
      self.$emit("sendSMS-shipTo", self._shipTo);
    },
    actionEventEmail() {
      const self = this;
      self.$emit("sendEmail-shipTo", self._shipTo);
    },
  },
};
</script>

<style scoped>
.cardShipToShadow {
  width: 100%;
  height: 100vh;
  background: #000015;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10100;
}
.cardShipToSendSMS {
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
}
.cardShipToSendSMS-content {
  position: absolute;
  right: 0;
  top: 0;
  width: 90%;
  height: 100vh;
  max-width: 700px;
  background: #fff;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.25);
  z-index: 10200;
  padding-top: 53.78px;
  padding-left: 3em;
  padding-right: 3em;

  overflow-y: auto;
}
.btnClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.5em;
  cursor: pointer;
}

.cardShipToSendSMS-title {
  font-size: 20px;
  margin-bottom: 1em;
}
.cardShipToSendSMS-subtitle {
  width: 100%;
  font-size: 16px;
  /* margin-top: 1em;
  margin-bottom: 1em; */
  padding: 1em;
  line-height: 1.3;
}
.btn-plus {
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.btn-plus:hover {
  background: rgba(0, 0, 0, 0.05);
}

.cardShipToSendSMS-inputGroup {
  width: 25%;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.cardShipToSendSMS-sectionButtons {
  padding: 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cardShipToSendSMS-button {
  margin-left: 1em;
  font-size: 14px;
}
</style>